var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hv-opener" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.isOpen)
            ? _vm._i(_vm.isOpen, null) > -1
            : _vm.isOpen,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.isOpen,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.isOpen = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.isOpen = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.isOpen = $$c
            }
          },
        },
      }),
      _vm._v(" " + _vm._s(_vm.textCheckbox) + "\n    "),
      _vm.isOpen ? _vm._t("content-to-open") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }