<template>
    <div class="hv-opener">
        <input type="checkbox" v-model="isOpen" > {{ textCheckbox }}
        <slot name="content-to-open" v-if="isOpen"></slot>
    </div>
</template>

<script>
export default {

    props:
    {
        textCheckbox:
        {
            type: String,
            default: ""
        }
    },
    data() {
      return {
        isOpen: false
      }
    }
}
</script>
